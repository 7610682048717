<template>
  <div>
    <div class="small-card">
      <b-row>
        <b-col lg="4" cols="12">
          <b-card>
            <div class="d-flex justify-content-between align-items-center">
              <div>รอยอดวางประกัน</div>
              <div>
                <span style="font-size: 20px" class="mr-1">
                  <span v-if="StatusCount('pending-deposit') > 0">
                    <b-badge variant="light-success">
                      {{ StatusCount("pending-deposit") }}
                    </b-badge>
                  </span>
                </span>
                <b-button
                  v-if="ROLE === 'owner'"
                  size="sm"
                  variant="primary"
                  @click="clickOrderStatus('pending-deposit')"
                >
                  >
                </b-button>
              </div>
            </div>
          </b-card>
        </b-col>
        <b-col lg="4" cols="12">
          <b-card>
            <div class="d-flex justify-content-between align-items-center">
              <div>รอโทรแจ้งยอด</div>
              <div>
                <span style="font-size: 20px">
                  <span v-if="StatusCount('pending-call') > 0" class="mr-1">
                    <b-badge variant="light-success">
                      {{ StatusCount("pending-call") }}
                    </b-badge>
                  </span>
                  <span
                    v-if="StatusCount('pending-call-not-ready') > 0"
                    class="mr-1"
                  >
                    <b-badge variant="light-warning">
                      {{ StatusCount("pending-call-not-ready") }}
                    </b-badge>
                  </span>
                </span>

                <b-button
                  size="sm"
                  variant="primary"
                  @click="clickOrderStatus('pending-call')"
                >
                  >
                </b-button>
              </div>
            </div>
          </b-card>
        </b-col>
        <b-col lg="4" cols="12">
          <b-card>
            <div class="d-flex justify-content-between align-items-center">
              <div>ติดต่อไม่ได้</div>
              <div>
                <span style="font-size: 20px" class="mr-1">
                  <span v-if="StatusCount('not-response') > 0" class="mr-1">
                    <b-badge variant="light-success">
                      {{ StatusCount("not-response") }}
                    </b-badge>
                  </span>

                  <span v-if="StatusCount('not-response-no-lock') > 0">
                    <b-badge variant="light-warning">
                      {{ StatusCount("not-response-no-lock") }}
                    </b-badge>
                  </span>
                </span>
                <b-button
                  size="sm"
                  variant="primary"
                  @click="clickOrderStatus('not-response')"
                >
                  >
                </b-button>
              </div>
            </div>
          </b-card>
        </b-col>
        <b-col lg="4" cols="12">
          <b-card>
            <div class="d-flex justify-content-between align-items-center">
              <div>ลูกค้าแจ้งโอนเงิน</div>
              <div>
                <span style="font-size: 20px" class="mr-1">
                  <span v-if="StatusCount('transfered') > 0">
                    <b-badge variant="light-success">
                      {{ StatusCount("transfered") }}
                    </b-badge>
                  </span>
                </span>
                <b-button
                  size="sm"
                  variant="primary"
                  @click="clickOrderStatus('transfered')"
                >
                  >
                </b-button>
              </div>
            </div>
          </b-card>
        </b-col>
        <b-col lg="4" cols="12">
          <b-card>
            <div class="d-flex justify-content-between align-items-center">
              <div>รอลูกค้าโอนค่าเช่า</div>
              <div>
                <span style="font-size: 20px" class="mr-1">
                  <span v-if="StatusCount('pending-transfer') > 0">
                    <b-badge variant="light-success">
                      {{ StatusCount("pending-transfer") }}
                    </b-badge>
                  </span>
                </span>
                <b-button
                  size="sm"
                  variant="primary"
                  @click="clickOrderStatus('pending-transfer')"
                >
                  >
                </b-button>
              </div>
            </div>
          </b-card>
        </b-col>
        <b-col lg="4" cols="12"
          ><b-card>
            <div class="d-flex justify-content-between align-items-center">
              <div>รอลูกค้าทำรายการ (ใบจอง)</div>
              <div>
                <span style="font-size: 20px" class="mr-1">
                  <span v-if="StatusCount('pending-info-no-lock') > 0">
                    <b-badge variant="light-success">
                      {{ StatusCount("pending-info-no-lock") }}
                    </b-badge>
                  </span>
                </span>
                <b-button size="sm" variant="primary" @click="clickPreBook()">
                  >
                </b-button>
              </div>
            </div>
          </b-card>
        </b-col>
        <b-col lg="4" cols="12">
          <b-card>
            <div class="d-flex justify-content-between align-items-center">
              <div>รอลูกค้าตอบกลับ</div>
              <div>
                <span style="font-size: 20px" class="mr-1">
                  <span v-if="StatusCount('pending-confirm') > 0">
                    <b-badge variant="light-success">
                      {{ StatusCount("pending-confirm") }}
                    </b-badge>
                  </span>
                </span>
                <b-button
                  size="sm"
                  variant="primary"
                  @click="clickOrderStatus('pending-confirm')"
                >
                  >
                </b-button>
              </div>
            </div>
          </b-card>
        </b-col>
        <b-col lg="4" cols="12">
          <b-card>
            <div class="d-flex justify-content-between align-items-center">
              <div>รอโอนเงินประกันคืน</div>
              <div>
                <span style="font-size: 20px" class="mr-1">
                  <b-badge variant="light-success" v-if="pending_refund_count">
                    {{ pending_refund_count }}
                  </b-badge>
                </span>
                <b-button
                  size="sm"
                  variant="primary"
                  @click="clickPendingRefund()"
                >
                  >
                </b-button>
              </div>
            </div>
          </b-card>
        </b-col>

        <b-col lg="4" cols="12">
          <b-card>
            <div class="d-flex justify-content-between align-items-center">
              <div>เช็ค Promtpay</div>
              <div>
                <span style="font-size: 20px" class="mr-1">
                  <b-badge
                    variant="light-danger"
                    v-if="pending_promptpay_count"
                  >
                    <h2>{{ pending_promptpay_count }}</h2>
                  </b-badge>
                </span>
                <b-button
                  size="sm"
                  variant="primary"
                  @click="clickCheckPromptPay()"
                >
                  >
                </b-button>
              </div>
            </div>
          </b-card>
        </b-col>
      </b-row>
    </div>

    <div class="row small-card">
      <div class="col-lg-8 col-12">
        <b-card>
          <h4>เลขที่สัญญาเช่า</h4>
          <div class="d-flex align-items-stretch">
            <b-form-input
              class="mr-1"
              v-model="form.order_id"
              name="order_id"
            />
            <b-button variant="primary" @click="openOrder()"> > </b-button>
          </div>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BCol,
  BRow,
  BContainer,
  BCard,
  BCardText,
  BLink,
  BButton,
  BCardBody,
  BCardTitle,
  BFormInput,
  BFormGroup,
  BBadge,
} from "bootstrap-vue";
import { getUserRole } from "@/auth/utils";

export default {
  components: {
    BCard,
    BCardText,
    BLink,
    BButton,
    BCardBody,
    BCardTitle,
    BFormInput,
    BFormGroup,
    BCol,
    BRow,
    BContainer,
    BBadge,
  },
  data() {
    return {
      ROLE: "staff",
      order_count: [
        {
          req: "pending-deposit",
          text: "รอยอดวางประกัน",
          variant: "warning",
          lock_queue: true,
          order: 7,
          count: 0,
        },
        {
          req: "pending-call",
          text: "รอโทรแจ้งยอดวางประกัน",
          variant: "warning",
          lock_queue: true,
          order: 9,
          count: 0,
        },
      ],
      pending_refund_count: 0,
      pending_promptpay_count: 0,
      form: {},
    };
  },
  created() {
    this.loadData();
    this.ROLE = getUserRole();
  },
  methods: {
    loadData() {
      this.$http({
        method: "GET",
        url: `/dashboard/order-status-count`,
      }).then((x) => {
        this.order_count = x.data.data;
      });

      this.$http({
        method: "GET",
        url: `/dashboard/pending-refund-count`,
      }).then((x) => {
        this.pending_refund_count = x.data.data;
      });

      this.$http({
        method: "GET",
        url: `/dashboard/pending-promptpay-count`,
      }).then((x) => {
        this.pending_promptpay_count = x.data.data;
      });
    },
    openOrder() {
      if (this.form.order_id) {
        this.$http({
          method: "POST",
          url: `/dashboard/open-order`,
          data: this.form,
        }).then((x) => {
          window.open("/order/view/" + x.data.data, "_blank");
        });
      }
    },
    clickPreBook() {
      this.$router.push("/order/pre-book/");
    },
    clickOrderStatus(status) {
      this.$router.push("/order/status/" + status);
    },
    clickPendingRefund() {
      this.$router.push("/account/pending-refund/");
    },
    clickCheckPromptPay() {
      this.$router.push("/member/promptpay-check");
    },
    StatusCount(status) {
      const temp = this.order_count.find((x) => x.req === status);
      if (temp) {
        return temp.count;
      } else {
        return 0;
      }
    },
  },
};
</script>

<style>
.small-card .card-body {
  padding: 1rem !important;
}
/* .small-card .card {
  margin-bottom: 1rem !important;
} */
</style>
